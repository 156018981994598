import Cookies from 'vue-cookies';
import Vue from 'vue';
import VueResource from 'vue-resource';
import qs from 'qs';
Vue.use(Cookies);
Vue.use(VueResource);
import base from '@/api/base.js';
let path = '';
let post = (url, json)=>{
  return new Promise((resolve,reject) => {
      Vue.http.options.emulateJSON = false;
      const api =  base.host + url
      const params = json || '';
      Vue.http
        .post(api, params)
        .then((response) => {
          if (response && response.body && response.status == 200 ) {
            resolve(response.body);
          } else {
            resolve(response);
          }
         },(response)=>{
          if(response){
            response = response.body;
            reject();
          }else{
            reject();
          }
        });
    });
}

let get = (url,json,isOtherApi)=>{
  return new Promise((resolve,reject) => {
    Vue.http.options.emulateJSON = true;
    let path = isOtherApi ? url : ('/api/' + url + (json ? ('?' + qs.stringify(json)) : '' ))
    Vue.http.get( path)
    .then((response) => {
      if (response && response.body && response.body.error_code == 0 ) {
        resolve(response.body);
      } else {
        resolve(response);
      }
    },(response)=>{
      if(response){
        response = response.body;
        reject();
      }else{
        reject();
      }
    });
  });
}


let jsonp = (url,json)=>{
  return new Promise((resolve,reject) => {
    Vue.http.options.emulateJSON = true;
    let path = url + (json ? ('?' + qs.stringify(json)) : '' );
  
    Vue.http({
        url: path,
        method: 'JSONP'
    })
    .then((response) => {
      if (response && response.body && response.body.error_code == 0 ) {
        resolve(response.body);
      } else {
        resolve(response);
      }
    },(response)=>{
      if(response){
        response = response.body;
        reject();
      }else{
        reject();
      }
    });
  });
}

let getCookie =  (name) =>{
  // return Vue.cookies.get(name);
}

let setCookie = (obj) =>{
  var time = obj.day ? obj.day : 7;
  Vue.cookies.set(obj.name, obj.value,time * 60 * 60 * 24)
}

let removeCookie = (name) =>{
  Vue.cookies.remove(name);
}

let unescape = (html) =>{
  return html
    .replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, '\'');
}

let dynamicLoading =  {
    css(path) {
      if (!path || path.length === 0) {
        throw new Error('argument "path" is required !');
      }
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.href = path;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      head.appendChild(link);
    },
    js(path, bodyEnd) {
      if (!path || path.length === 0) {
        throw new Error('argument "path" is required !');
      }
      const bodyEndAppend = bodyEnd || false;
      let head = document.getElementsByTagName('head')[0];
      if (bodyEndAppend) {
        head = document.getElementsByTagName('body')[0];
      }
      const script = document.createElement('script');
      script.src = path;
      script.type = 'text/javascript';
      head.appendChild(script);
    }
};


// 设置authToken
Vue.http.headers.common.authorization = getCookie('token') || '';

export default {
  path: path,
  post : post,
  get: get,
  jsonp: jsonp,
  getCookie : getCookie,
  setCookie : setCookie,
  removeCookie: removeCookie,
  unescape : unescape,
  dynamicLoading: dynamicLoading
};